body {
  overflow: auto;
}

.pushable {
  min-height: 100vh;
}

.ui.left.sidebar, .ui.right.sidebar {
  height: 100vh;
  width: 240px !important;
  padding: 15px;
  position: fixed !important;
  bottom: 0px !important;
  top: 0px !important;
}

.pusher {
  padding: 0px 150px 0px 90px;
  min-height: 95vh !important;
}

label {
  font-weight: bold;
  padding-right: 10px;
}

.ui.table thead th,
.ui.table tfoot th,
.ui.table td {
  padding: 6px;
}

.pointer {
  cursor: pointer;
}

.error-text {
  color: red;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.changed-highlight {
  border-color: #FF7043 !important;
  box-shadow: 0 0 8px #FF7043 !important;
}

.ui[class*="right labeled"].icon.button.fixed-padding {
  padding-left: 0 !important;
  padding-right: 3em !important;
}

.dark-negative {
  background-color: red;
}